<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="-1"
            class="elevation-1"
            hide-default-footer
        >
            <!--            <template v-slot:bottom></template>-->
            <template v-slot:top>
                <v-toolbar
                    flat
                >
                    <v-toolbar-title>{{ $t("expense_reports.purchases.title") }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    ></v-divider>
                    <v-btn :disabled="disabled" prepend-icon="mdi-receipt-text-plus" @click="newItem">
                        {{ $t("expense_reports.purchases.new") }}
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                    class="me-2"
                    size="small"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    size="small"
                    @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
            </template>

            <template v-slot:item.document="{item}">
                <DynamicElement :uuid="item.document" type="document"></DynamicElement>
            </template>
        </v-data-table>


        <v-dialog
            v-model="dialog"
            persistent
            width="1024"
        >
            <v-card
                :disabled="disabled"
                :loading="loading"
            >
                <v-card-title>
                    <span class="text-h5">{{ $t("expense_reports.purchases.new") }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form enctype="multipart/form-data">
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="6"
                                >
                                    <v-text-field
                                        v-model="date"
                                        :disabled="loading"
                                        :label="$t('expense_reports.purchases.date')"
                                        outlined
                                        required
                                        type="date"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="6"
                                >
                                    <v-text-field
                                        v-model="type"
                                        :disabled="loading"
                                        :label="$t('expense_reports.purchases.type')"
                                        outlined
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="6"
                                >
                                    <v-text-field
                                        v-model="amount"
                                        :disabled="loading"
                                        :label="$t('expense_reports.purchases.amount')"
                                        outlined
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        v-model="description"
                                        :disabled="loading"
                                        :label="$t('expense_reports.purchases.description')"
                                        outlined
                                        required
                                    ></v-textarea>
                                </v-col>
                                <v-col cols="12">
                                    <DynamicElement :uuid="document_uuid" type="document"></DynamicElement>
                                    <v-file-input
                                        v-model="document"
                                        :disabled="loading"
                                        :label="$t('expense_reports.purchases.document')"
                                        outlined
                                        required
                                        show-size
                                    ></v-file-input>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="loading"
                        color="secondary"
                        text
                        @click="closeDialog"
                    >
                        {{ $t("expense_reports.purchases.close") }}
                    </v-btn>
                    <v-btn
                        :disabled="loading"
                        color="secondary"
                        text
                        @click="saveItem"
                    >
                        {{ $t("expense_reports.purchases.save") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Accounting from "@/helpers/Accounting";
import DynamicElement from "@/components/DynamicElement.vue";

export default {
    name: "ExpenseReportPurchases",
    components: {DynamicElement},
    props: ["value", "disabled"],
    data: () => ({
        headers: [],
        items: [],
        dialog: false,
        loading: false,

        document: null,
        document_uuid: null,
        type: null,
        date: null,
        description: null,
        amount: null,

        editedItem: -1,

        rules: {}
    }),
    mounted() {
        this.headers = [
            {text: this.$t("expense_reports.purchases.date"), value: "date"},
            {text: this.$t("expense_reports.purchases.type"), value: "type"},
            {text: this.$t("expense_reports.purchases.description"), value: "description"},
            {text: this.$t("expense_reports.purchases.amount"), value: "amount"},
            {text: this.$t("expense_reports.purchases.document"), value: "document"},
            {text: this.$t("expense_reports.purchases.actions"), value: "actions", sortable: false}
        ];

        if (this.value === null) {
            this.items = [];
        } else {
            this.items = this.value;
        }
    },
    watch: {
        value(val) {
            this.items = val;
        },
        disabled(val) {
            if (val) {
                this.dialog = false;
            }
        }
    },
    methods: {
        update() {
            // this.$emit("update:modelValue", this.items)
            this.$emit("input", this.items);
        },
        newItem() {
            this.dialog = true;

            this.date = null;
            this.type = null;
            this.description = null;
            this.amount = null;
            this.document = null;
            this.document_uuid = null;

            this.editedItem = -1;
        },
        closeDialog() {
            this.dialog = false;
        },
        async saveItem() {
            this.loading = true;

            let doc = null;

            if (this.document) {
                let formData = new FormData();
                formData.append("file", this.document);
                formData.append("name", this.document.name);

                doc = await Accounting.upload("/documents", formData)
                    .catch(() => {
                        // TODO: Add error message
                    });
            }

            if (doc || this.document_uuid) {

                let obj = {
                    date: this.date,
                    type: this.type,
                    description: this.description,
                    amount: this.amount,
                    document: doc != null ? doc.data.uuid : this.document_uuid
                };

                if (this.editedItem === -1) {
                    this.items.push(obj);
                } else {
                    Object.assign(this.items[this.editedItem], obj);
                }


                this.dialog = false;
                this.loading = false;

                this.update();

                this.date = null;
                this.type = null;
                this.description = null;
                this.amount = null;
                this.document = null;
                this.document_uuid = null;

            }
        },
        editItem(item) {
            if (this.disabled) return;

            this.date = item.date;
            this.type = item.type;
            this.description = item.description;
            this.amount = item.amount;
            this.document_uuid = item.document;

            this.dialog = true;
            this.editedItem = this.items.indexOf(item);
        },
        deleteItem(item) {
            if (this.disabled) return;

            this.$swal({
                title: this.$t("expense_reports.purchases.delete_title"),
                text: this.$t("expense_reports.purchases.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("expense_reports.purchases.delete_yes"),
                cancelButtonText: this.$t("expense_reports.purchases.delete_no")
            }).then(result => {
                if (result.isConfirmed) {
                    this.items.splice(this.items.indexOf(item), 1);
                    this.update();
                }
            });

        }
    }
};
</script>

<style scoped>

</style>