<template>
    <div class="ml-10 mr-10">
        <TitleBar
            :disabled="loading"
            :loading="loading"
            :menu="menu"
        >
            {{ $t("expense_reports.edit") }}
        </TitleBar>

        <Tags :element="$route.params.uuid"></Tags>

        <v-alert
            v-if="status === 'draft'"
            border="left"
            type="info"
        >
            {{ $t("expense_reports.draft") }}
        </v-alert>

        <v-alert
            v-if="status === 'denied'"
            border="left"
            type="error"
        >
            {{ $t("expense_reports.denied") }}
        </v-alert>

        <v-card
            :loading="loading"
            class="mb-8"
            outlined
        >
            <v-list-item three-line>
                <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1">
                        {{ user.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                    color="grey"
                    rounded
                    size="80"
                >
                    <v-img :src="user.avatar"></v-img>
                </v-list-item-avatar>
            </v-list-item>
        </v-card>

        <v-card
            v-if="status === 'sent'"
            :loading="loading"
            class="mb-8"
            outlined
        >
            <v-card-title>{{ $t("expense_reports.decide") }}</v-card-title>
            <v-card-subtitle class="pb-0" v-html="$t('expense_reports.amount', {total: total})"></v-card-subtitle>
            <v-card-text class="pb-0">
                <p class="ma-0 pa-0"><strong>{{ $t("expense_reports.iban") }}</strong>: {{ user.iban }}</p>
                <p class="ma-0 pa-0"><strong>{{ $t("expense_reports.bic") }}</strong>: {{ user.bic }}</p>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    :disabled="loading"
                    color="green"
                    text
                    @click="status = 'approved';formSubmit()"
                >{{ $t("expense_reports.approve") }}
                </v-btn>
                <v-btn
                    :disabled="loading"
                    color="red"
                    text
                    @click="status = 'denied';formSubmit()"
                >{{ $t("expense_reports.deny") }}
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-card
            v-if="status === 'approved'"
            :loading="loading"
            class="mb-8"
            outlined
        >
            <v-card-title>{{ $t("expense_reports.refund") }}</v-card-title>
            <v-card-subtitle class="pb-0" v-html="$t('expense_reports.amount', {total: total})"></v-card-subtitle>
            <v-card-text class="pb-0">
                <p class="ma-0 pa-0"><strong>{{ $t("expense_reports.iban") }}</strong>: {{ user.iban }}</p>
                <p class="ma-0 pa-0"><strong>{{ $t("expense_reports.bic") }}</strong>: {{ user.bic }}</p>
            </v-card-text>
            <v-card-actions>

                <v-dialog
                    v-model="dialog"
                    width="500"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            :disabled="loading"
                            color="green"
                            text
                            v-bind="attrs"
                            v-on="on"
                        >{{ $t("expense_reports.mark_refunded") }}
                        </v-btn>
                    </template>

                    <v-card
                        :loading="loading"
                    >
                        <v-card-title class="text-h5 grey lighten-2">
                            {{ $t("expense_reports.mark_refunded") }}
                        </v-card-title>

                        <v-card-text>

                            <p class="ma-0 pa-0 mt-3"><strong>{{ $t("expense_reports.iban") }}</strong>: {{ user.iban }}
                            </p>
                            <p class="ma-0 pa-0 mb-3"><strong>{{ $t("expense_reports.bic") }}</strong>: {{ user.bic }}
                            </p>

                            <p>{{ $t("expense_reports.refund_select") }}</p>

                            <v-form @submit.prevent="refund">
                                <v-select
                                    v-model="category"
                                    :disabled="loading"
                                    :items="categories"
                                    :label="$t('payments.category')"
                                    item-text="name"
                                    item-value="uuid"
                                    outlined
                                ></v-select>

                                <v-select
                                    v-model="account"
                                    :disabled="loading"
                                    :items="accounts"
                                    :label="$t('payments.account')"
                                    item-text="name"
                                    item-value="uuid"
                                    outlined
                                ></v-select>
                            </v-form>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                :disabled="loading"
                                color="secondary"
                                text
                                @click="refund"
                            >
                                {{ $t("expense_reports.mark_refunded") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-card-actions>
        </v-card>

        <v-form @submit.prevent="formSubmit">
            <v-text-field
                v-model="approved_by"
                :disabled="loading"
                :error-messages="approvedByErrors"
                :label="$t('expense_reports.approved_by')"
                outlined
                @blur="$v.approved_by.$touch()"
                @input="$v.approved_by.$touch()"
            ></v-text-field>

            <v-textarea
                v-model="description"
                :disabled="loading"
                :error-messages="descriptionErrors"
                :label="$t('expense_reports.description')"
                outlined
                @blur="$v.description.$touch()"
                @input="$v.description.$touch()"
            ></v-textarea>

            <v-select
                v-model="status"
                :disabled="loading"
                :items="statues"
                :label="$t('expense_reports.status')"
                outlined
            ></v-select>

            <ExpenseReportPurchases v-model="items" :disabled="loading"></ExpenseReportPurchases>

            <v-btn
                :loading="loading"
                class="mt-4"
                color="secondary"
                elevation="2"
                type="submit"
            >{{ $t("expense_reports.update") }}
            </v-btn>
        </v-form>


        <!--        Fields -->

        <Attachments :element="$route.params.uuid"></Attachments>

    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Accounting from "../../helpers/Accounting";
import Tags from "../../components/Tags";
import Attachments from "../../components/Attachments";
import Utils from "../../helpers/Utils";
import TitleBar from "@/components/TitleBar.vue";
import ExpenseReportPurchases from "@/components/ExpenseReportPurchases.vue";
import md5 from "md5";
import Statuses from "@/helpers/Statuses";

export default {
    name: "Details",
    components: {ExpenseReportPurchases, TitleBar, Attachments, Tags},
    async mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.expense_reports"),
                to: "/expenses/expense-reports",
                exact: true
            },
            {
                text: this.$t("expense_reports.edit"),
                to: "/expenses/expense-reports/" + this.$route.params.uuid,
                exact: true
            }
        ]);

        this.menu.push({text: this.$t("expense_reports.delete"), callback: this.deleteElement, class: "red--text"});

        this.statues = Statuses.get(this.$i18n.locale, ["viewed", "partial", "paid", "received", "cancelled", "voided"]);
        // ['draft', 'sent', 'approved', 'denied', 'refunded']

        this.loading = true;

        let categories = await Accounting.get("/categories?fields=name,uuid&per_page=-1&page=1").catch(() => {
        });
        this.categories = categories.data.categories;

        let accounts = await Accounting.get("/accounts?fields=name,uuid&per_page=-1&page=1").catch(() => {
        });
        this.accounts = accounts.data.accounts;

        Accounting.get("/expenses/expense-reports/" + this.$route.params.uuid)
            .then(response => {
                // eslint-disable-next-line no-unused-vars
                const data = response.data;
                this.user.uuid = data.user;
                this.status = data.status;
                this.description = data.description;
                this.approved_by = data.approved_by;
                this.items = data.items;
                this.total = data.amount;
                this.expense_report_number = data.expense_report_number;

                this.loading = false;

                this.loadUser();
            }).catch(error => {
            if (error.response) {
                let errors = [];
                for (const errorElement of error.response.data.errors) {
                    errors.push({
                        type: "error",
                        text: errorElement
                    });
                }
                this.$store.commit("SET_ALERTS", errors);
            } else if (error.request) {
                this.$store.commit("SET_ALERTS", [{
                    type: "error",
                    text: this.$t("api.no_response")
                }]);
            } else {
                this.$store.commit("SET_ALERTS", [{
                    type: "error",
                    text: error.message
                }]);
            }
            this.loading = false;
        });

    },
    data: () => {
        return {
            loading: false,
            menu: [],
            user: {
                uuid: null,
                name: null,
                email: null,
                avatar: null,
                iban: null,
                bic: null
            },
            status: null,
            approved_by: null,
            description: null,
            items: null,
            expense_report_number: null,
            total: null,
            statues: ["draft", "sent", "approved", "denied", "refunded"],

            dialog: false,
            categories: [],
            accounts: [],
            category: null,
            account: null
        };
    },
    methods: {
        async refund() {
            this.loading = true;
            let contacts = await Accounting.get("/contacts?fields=name,uuid,email&per_page=-1&page=1").catch(() => {
            });

            let selectedContact = null;

            for (const contact of contacts.data.contacts) {
                if (contact["name"] === this.user.name && contact["email"] === this.user.email) {
                    selectedContact = contact["uuid"];
                }
            }

            let company = await Accounting.get("/companies/" + this.$store.state.company.uuid)
                .catch(() => {
                });

            if (company === null) {
                this.loading = false;
                return;
            }

            if (selectedContact === null) {
                let customer = await Accounting.post("/contacts", {
                    currency: company.data.currency,
                    email: this.user.email,
                    name: this.user.name
                }).catch(() => {
                    this.loading = false;

                });

                selectedContact = customer.data.uuid;
            }

            Accounting.post("/tags", {
                attached_at: selectedContact,
                key: "expense_report_user",
                value: this.user.uuid
            }).catch(() => {
            });

            Accounting.post("/tags", {
                attached_at: this.$route.params.uuid,
                key: "contact",
                value: selectedContact
            }).catch(() => {
            });

            const now = new Date();
            let date = (now.getFullYear()) + "-" + ((now.getMonth() + 1) + "").padStart(2, "0") + "-" + (now.getDate() + "").padStart(2, "0");

            Accounting.post("/expenses/payments", {
                account: this.account,
                amount: this.total,
                category: this.category,
                currency_code: company.data.currency,
                date: date,
                payment_method: "transfer",
                contact: selectedContact,
                reference: this.expense_report_number
            }).then(res => {
                this.status = "refunded";
                this.loading = false;
                this.dialog = false;
                this.formSubmit();

                Accounting.post("/tags", {
                    attached_at: res.data.uuid,
                    key: "expense_report",
                    value: this.$route.params.uuid
                }).catch(() => {
                });

                Accounting.get("/attachments/resource/" + this.$route.params.uuid)
                    .then(async (response) => {
                        let attachments = response.data.attachments;

                        for (let i = 0; i < attachments.length; i++) {
                            const element = attachments[i];
                            Accounting.post("/attachments", {
                                attached_to: res.data.uuid,
                                document: element.document
                            }).catch(() => {
                            });
                        }
                    })
                    .catch(() => {
                    });
            }).catch(() => {
                this.loading = false;
            });

        },
        loadUser() {
            this.loading = true;
            Accounting.get("/expenses/expense-reports/users/" + this.user.uuid)
                .then(res => {
                    // eslint-disable-next-line no-unused-vars
                    const data = res.data;
                    this.user.email = data.email;
                    this.user.name = data.first_name + " " + data.last_name;
                    this.user.avatar = "https://www.gravatar.com/avatar/" + md5(data.email);
                    this.user.iban = data.iban;
                    this.user.bic = data.bic;
                })
                .catch(error => {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        deleteElement() {
            this.$swal({
                title: this.$t("expense_reports.delete_title"),
                text: this.$t("expense_reports.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("expense_reports.delete_yes"),
                cancelButtonText: this.$t("expense_reports.delete_no"),
                confirmButtonColor: Utils.getColor(this, "error"),
                cancelButtonColor: Utils.getColor(this, "primary")
            }).then(result => {
                if (result.isConfirmed) {
                    this.loading = true;
                    this.$store.commit("SET_ALERTS", []);
                    Accounting.delete("/expenses/expense-reports/" + this.$route.params.uuid)
                        .then(() => {
                            this.$store.commit("SET_ALERTS", [{
                                type: "success",
                                text: this.$t("expense_reports.deleted")
                            }]);
                            this.loading = false;
                            this.$router.push("/expenses/expense-reports");
                        })
                        .catch(error => {
                            if (error.response) {
                                let errors = [];
                                for (const errorElement of error.response.data.errors) {
                                    errors.push({
                                        type: "error",
                                        text: errorElement
                                    });
                                }
                                this.$store.commit("SET_ALERTS", errors);
                            } else if (error.request) {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: this.$t("api.no_response")
                                }]);
                            } else {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: error.message
                                }]);
                            }
                            this.loading = false;
                        });
                }
            });
        },
        formSubmit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;

                this.$store.commit("SET_ALERTS", []);

                Accounting.post("/expenses/expense-reports/" + this.$route.params.uuid, {
                    approved_by: this.approved_by,
                    description: this.description,
                    items: this.items,
                    status: this.status,
                    user: this.user.uuid
                }).then(() => {

                    this.loading = false;

                    this.$store.commit("SET_ALERTS", [{
                        type: "success",
                        text: this.$t("expense_reports.updated")
                    }]);

                }).catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                    this.loading = false;
                });

            }
        }
    },
    mixins: [validationMixin],
    validations: {
        approved_by: {required},
        description: {required}
    },
    computed: {
        approvedByErrors() {
            const errors = [];
            if (!this.$v.approved_by.$dirty) return errors;
            !this.$v.approved_by.required && errors.push(this.$t("expense_reports.approved_by_required"));
            return errors;
        },
        descriptionErrors() {
            const errors = [];
            if (!this.$v.description.$dirty) return errors;
            !this.$v.description.required && errors.push(this.$t("expense_reports.description_required"));
            return errors;
        }
    }
};
</script>

<style scoped>

</style>